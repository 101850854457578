import { defineStore } from "pinia";
import axios, {type AxiosRequestConfig} from 'axios'
import { useRuntimeConfig } from "nuxt/app";
import helper from "~/resources/js/libraries/helper";

export const useUploadMediaStore = defineStore("uploadMedia", {
	state: () => ({
        
	}),
    getters: {
        
    },
	actions: {
        getVcrmAccountToken() {
			const vue_cookie = document.cookie.split("; ").find((row) => row.startsWith("vcrm-account-token"));
			if (vue_cookie != undefined && vue_cookie.split("=")[1] != "") {
				return vue_cookie.split("=")[1];
			}
		},

        async uploadMedia(reqConfig: AxiosRequestConfig) {
            const config = useRuntimeConfig();
            const uploadMediaUrl = config.public.uploadMediaServiceUrl as string;

            const vue_cookie = this.getVcrmAccountToken();
			if (!vue_cookie) {
				return false;				
			}

            if (!reqConfig.headers) {
                reqConfig.headers = {}
            }

            reqConfig.headers['authorization'] = `Bearer ${vue_cookie}`
            reqConfig['url'] = `${uploadMediaUrl}/base64`

			return await axios.request(reqConfig)
        },
        
    }
});